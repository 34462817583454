<template>
<div>

    <!-- Filters -->
    <!-- <template>
        <b-card no-body class="p-1">
            <b-card-header class="pb-50">
                <h5>
                    Filters
                </h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>{{ $t('Asset')}}</label>
                        <vue-autosuggest v-model="searchQueryAssets" :suggestions="filteredOptionsAsset" :input-props="inputPropsAsset" @selected="selectHandlerAsset" @input="onInputChangeAsset">
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.plate ? suggestion.item.plate : suggestion.item.chassisNumber }}</span>
                            </template>
                        </vue-autosuggest>
                    </b-col>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>{{ $t('Date Start')}}</label>
                        <flat-pickr v-model="dateStart" class="form-control" />
                    </b-col>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>{{ $t('Date End')}}</label>
                        <flat-pickr v-model="dateEnd" class="form-control" />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </template> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <!-- <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>{{ $t('Show')}}</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>{{ $t('entries')}}</label>
                </b-col> -->

                 <!-- Search -->
                 <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <b-button variant="primary" :to="{ name: 'register-maintenance-plan' }">
                            <span class="text-nowrap">{{ $t('Add Maintenance Plan')}}</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table ref="refMaintenancePlanListTable" class="position-relative" :items="fetchAllMaintenancePlan" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="emptyText" :sort-desc.sync="isSortDirDesc">

            <!-- Column: Customer Name -->
            <template #head(customer)>
                {{ $t('Owner')}}
            </template>
            <template #cell(customer)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.customerName }}
                </div>
            </template>

            <!-- Column: Name -->
            <template #head(name)>
                {{ $t('Name')}}
            </template>
            <template #cell(name)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.name }}
                </div>
            </template>

            <!-- Column: Actions -->
            <template #head(actions)>
                {{ $t('Actions')}}
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret >

                    <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item :to="{ name: 'register-maintenance-plan', params: { id: data.item.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">{{ $t('Edit')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item :to="{ name: 'maintenance-assets', params: { id: data.item.id } }">
                        <feather-icon icon="ArrowRightCircleIcon" />
                        <span class="align-middle ml-50">{{ $t('Open')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="modalDelete(data.item.id)">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">{{ $t('Delete')}}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted"> {{ $t('Showing')}} {{ dataMeta.from }} {{ $t('to')}} {{ dataMeta.to }} {{ $t('of')}} {{ dataMeta.of }} {{ $t('entries')}}</span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                    <b-pagination v-model="currentPage" :total-rows="totalMaintenancePlan" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
</div>
</template>

<script>


import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
} from 'bootstrap-vue'

import store from '@/store'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import {
    avatarText
} from '@core/utils/filter'
//import UsersListFilters from './components/UsersListFilters.vue'
import useMaintenancePlanList from './useMaintenancePlanList'
import managementStoreModule from '../managementStoreModule'

export default {
    components: {
        //UsersListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BCardHeader,
        BCardBody,

    },
    data() {
        return {
            emptyText: this.$t('No matching records found'),
        }
    },
    methods: {


        modalDelete(myId) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {
                    console.log('ID', myId)
                    this.deleteUser(myId)

                } else {}
            })
        },

        deleteUser(myId) {
            store.dispatch('app-management/deleteMaintenancePlan', {
                    id: myId
                })
                .then(response => {
                    this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                    this.refetchData()

                })
                .catch(error => {
                    this.$swal('Error', 'Your fuelling is still intact', 'error')
                    console.log(error);
                })
        }
    },
    setup() {
        const MANAGEMENT_APP_STORE_MODULE_NAME = 'app-management'

        // Register module
        if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.registerModule(MANAGEMENT_APP_STORE_MODULE_NAME, managementStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME)
        })


        const {
            fetchAllMaintenancePlan,
            tableColumns,
            perPage,
            currentPage,
            totalMaintenancePlan,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refMaintenancePlanListTable,
            refetchData,

        } = useMaintenancePlanList()

        return {
            fetchAllMaintenancePlan,
            tableColumns,
            perPage,
            currentPage,
            totalMaintenancePlan,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refMaintenancePlanListTable,
            refetchData,
        }
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
