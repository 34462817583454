import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMaintenancePlanList() { 
  // Use toast
  const toast = useToast()

  const refMaintenancePlanListTable = ref(null)  

  // Table Handlers
  const tableColumns = [
    { key: 'customer', sortable: false },
    { key: 'name', sortable: false },
    //{ key: 'customerName', sortable: true },
    { key: 'actions' },
  ]



  const perPage = ref(10)
  const totalMaintenancePlan = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  


  const dataMeta = computed(() => {
    const localItemsCount = refMaintenancePlanListTable.value ? refMaintenancePlanListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMaintenancePlan.value,
    }
  })

  const refetchData = () => {
    refMaintenancePlanListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchAllMaintenancePlan = (ctx, callback) => {
    
    store
      .dispatch('app-management/fetchAllMaintenancePlan', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const maintenance = response.data.data;
        console.log(`fetchAllMaintenancePlan: `, maintenance)
        
        callback(maintenance)
        totalMaintenancePlan.value = response.data.totalItems
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching  list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  

  

  

  return {
    fetchAllMaintenancePlan,
    tableColumns,
    perPage,
    currentPage,
    totalMaintenancePlan,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMaintenancePlanListTable,
    refetchData,
  }
}
